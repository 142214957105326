var table, tableSettings, view = 'list', hiddenColumns = [];

$('.results-table thead th[data-extra]').each(function() {
  hiddenColumns.push(parseInt($(this).attr('data-extra')));
});

if (Cookies.get('view') === 'card') {
  view = 'card';
}

/** Set filters and then filter data */
var urlVars = getUrlVars();

function isUrlFilter() {
  var checks = [];

  $.each(urlVars['keys'], function(index, filter_name) {
    if (filter_name.startsWith('filter_')) {
      checks.push(true);
    }
  });

  if (checks.indexOf(true) !== -1) {
    return true;
  }

  return false;
}

var method = 'GET';
if (isUrlFilter()) {
  method = 'POST';

  /** Stop select change running */
  single = false;

  /** Set filters */
  $.each(urlVars['keys'], function(index, filter_name) {
    if (filter_name.indexOf('filter_') > -1) {
      var values = urlVars['params'][filter_name].split(',').map(function (x) { 
        return decodeURIComponent(x).replace(/\+/g, " ");
      });

      values = values.length > 1 ? values : values[0];

      $('.filter-select[name="'+filter_name.replace('filter_', '')+'"]').val(values).trigger('change');
      $('.clear-filters-btn').show();
    }
  });
  
  generateSelectOptions();

  /** Set back to normal */
  single = true;
}


table = $('.results-table').DataTable({
  dom: '<lt<"bottom-bar"ip>>',
  responsive: true,
  ajax: {
    url: settings.dataUrl,
    type: method,
    data: selectedOptions,
    dataSrc: function (json) {
      return json.data.body;
    }
  },
  order: [],
  columns: [{ data: 'title' }, { data: 'institution' }, { data: 'unit_of_assessment' }, { data: 'impact_sectors' }, { data: 'topic_primary.label' }, { data: 'topic_secondary.label' }, { data: 'topic_tertiary.label' }, { data: 'funders' }, { data: 'research_categories' }, { data: null }],
  columnDefs: [
    { responsivePriority: 1, targets: 0 },
    { responsivePriority: 2, targets: -1 },
    { visible: false, targets: hiddenColumns },
    {
      render: function (data, type, row) {
        return '<h2>'+data+'</h2>';
      },
      targets: 0,
    },
    {
      render: function (data, type, row, meta) {
        var title = $('.results-table').DataTable().columns(meta.col).header(); 
        var columnName = $(title).html();
        
        return '<span class="col-header">'+columnName+': </span><span class="col-content">'+(data !== null ? data : '--')+'</span>';
      },
      targets: [1,4,5,6],
    },
    {
      render: function (data, type, row, meta) {
        var title = $('.results-table').DataTable().columns(meta.col).header(); 
        var columnName = $(title).html();

        if (type == 'display') {
          var unit_text = data.unit_of_assessment !== null ? data.unit_of_assessment : '--';
          return '<span class="col-header">'+columnName+': </span><span class="col-content">'+unit_text+'</span>';
        } else {
          return data.uoa;
        }
      },
      targets: [2]
    },
    {
      render: function (data, type, row, meta) {
        var title = $('.results-table').DataTable().columns(meta.col).header(); 
        var columnName = $(title).html();
        var sectors = '';
        
        $.each(data, function(index, value) {
          var isLastElement = index == data.length - 1;
          if (isLastElement) {
            sectors += value.name;
          } else {
            sectors += value.name+', ';
          }
        });

        var sectorsText = (sectors.length > 0) ? sectors : '--';

        return '<span class="col-header">'+columnName+': </span><span class="col-content">'+sectorsText+'</span>';
      },
      targets: [3],
    },
    {
      render: function (data, type, row, meta) {
        var title = $('.results-table').DataTable().columns(meta.col).header(); 
        var columnName = $(title).html();
        var funders = '';

        /** Sort data */
        data.sort(sortByWeight);
        
        $.each(data, function(index, value) {
          var isLastElement = index == data.length - 1,
            text = value.funder+' ('+Math.round(value.weight)+')';

          if (isLastElement) {
            funders += text;
          } else {
            funders += text+', ';
          }
        });

        var fundersText = (funders.length > 0) ? funders : '--';

        return '<span class="col-header">'+columnName+': </span><span class="col-content">'+fundersText+'</span>';
      },
      targets: [7],
    },
    {
      render: function (data, type, row, meta) {
        var title = $('.results-table').DataTable().columns(meta.col).header(); 
        var columnName = $(title).html();
        var categories = '';

        $.each(data, function(index, items) {
          categories += '<strong>'+index+'</strong>: ';

          $.each(items, function(index, cat) {
            var isLastElement = index == items.length - 1,
              text = cat.cat_label+' ('+Math.round(cat.weight)+')';

            if (isLastElement) {
              categories += text;
            } else {
              categories += text+', ';
            }
          });

          categories += '<br />';
        });

        var categoriesText = (categories.length > 0) ? categories : '--';

        return '<span class="col-header">'+columnName+': </span><span class="col-content">'+categoriesText+'</span>';
      },
      targets: [8],
    },
    {
      render: function (data, type, row) {
        return '<a href="/case-study/' + row['uid'].replace(/\_/g, '-') + '" class="btn btn-outline">View Case Study</a>';
      },
      targets: [9],
      orderable: false
    }
  ],
  initComplete: function (settings, json) {
    /** Move "show entries" dropdown to filter bar */
    $('#results-table_length').appendTo('.show-entries-wrapper');
    
    if (view === 'card') {  
      $('.results-table').addClass('card');
    } else {
      $('.results-table').removeClass('card');
    }

    /** Get the total count and render it to the page */
    updateResultsTable(json.data.total);

    /** Add counters */
    activateFilters(json.data.body);
  }
});

tableSettings = table.settings();

/** Update results title */
function updateResultsTable(count) {
  if ($('.search-results-bar').length > 0) {
    $('.search-results-bar .result-number').html(count);
    $('.search-results-bar .returned-text').show();
    
    /** Updates text */
    if (count === 1) {
      $('.search-results-bar .result-text').text('result');
    } else {
      $('.search-results-bar .result-text').text('results');
    }
  } else {
    $('.results-title .result-number').html(count);
  
    /** Updates text */
    if (count === 1) {
      $('.results-title .result-text').text('Case Study');
    } else {
      $('.results-title .result-text').text('Case Studies');
    }
  }
}

/** Show all rows */
$(document).on('click', '.show-all', function () {
  tableSettings[0]._iDisplayLength = tableSettings[0].fnRecordsTotal();
  table.draw();
});

/** Show and hide extra columns on card view */
showHideColumns();

function showHideColumns() {
  var visible = (view === 'card') ? true : false;

  $.each(hiddenColumns, function(index, value) {
    var column = table.column(value);
    column.visible(visible);
  });
}

/** Toggle view logic */
function toggleView() {
  $('.results-table').toggleClass('card');

  /** Update localStorage so we can keep the view on refresh */
  if ($('.results-table').hasClass('card')) {
    Cookies.set('view', 'card');
    view = 'card';
  } else {
    Cookies.set('view', 'list');
    view = 'list';
  }

  showHideColumns();
}

$(document).on('click', '.toggle-photos-view .btn', function () {
  var $self = $(this),
    $parent = $self.parents('.toggle-photos-view'),
    $input = $parent.find('.switch-input'),
    view = $self.attr('data-view');

  $('.toggle-photos-view .btn').removeClass('active');
  $self.addClass('active');

  if (view === 'list') {
    $input.prop('checked', false);
  } else {
    $input.prop('checked', true);
  }

  toggleView();
});

$(document).on('click', '.switch', function () {
  var $self = $(this),
    $input = $(this).find('.switch-input'),
    $parent = $self.parents('.toggle-photos-view');

  $('.toggle-photos-view .btn').removeClass('active');

  if (!$input.is(':checked')) {
    $parent.find('.btn[data-view="grid"]').addClass('active');
    $input.prop('checked', true).trigger('change');
  } else {
    $parent.find('.btn[data-view="list"]').addClass('active');
    $input.prop('checked', false).trigger('change');
  }

  toggleView();

  return false;
});

/** Sort by weight */
function sortByWeight(a, b) {
  if (a.weight > b.weight) {
    return -1;
  }
  if (a.weight < b.weight) {
    return 1;
  }
  return 0;
}