var single = true,
  selectedOptions = {};

$(document).on('click', '.filter-toggle', function () {
  var $self = $(this),
    $parent = $self.parents('.filter-bar');

  $parent.find('.filter-controls').stop().slideToggle();
  $self.toggleClass('active');
});


function getNextOptions($parent, $select) {
  /** Get current value list and append to it */
  var childValues = $select.val();

  $parent.nextUntil($('.level-1')).each(function() {
    var value = $(this).attr('data-value');

    /** If the item is already in the array remove it */
    var index = childValues.indexOf(value);
    if (index > -1) {
      childValues.splice(index, 1);
      $(this).removeClass('select2-results__option--selected');
    } else {
      childValues.push(value);
      $(this).addClass('select2-results__option--selected');
    }
  });

  $select.val(childValues).trigger('change');
}


/** Set filters from URL */
function getUrlVars() {
  var vars = {"keys": [], "params": []}, hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for(var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars['keys'].push(hash[0]);
    vars['params'][hash[0]] = hash[1];
  }
  return vars;
}

activateFilters();

function activateFilters(json, selectName, change) {
  json = typeof json !== 'undefined' ? json : false;
  change = typeof change !== 'undefined' ? change : false;
  selectName = typeof selectName !== 'undefined' ? selectName : false;

  $('.filters-wrapper .filter-select').each(function() {
    var $select = $(this);

    if (json) {
      var name = $select.attr('name');

      /** Handle custom name properties */
      if (typeof name !== 'undefined' && name === 'topic_labels') {
        name = 'topic_primary';
      }

      if (typeof name !== 'undefined' && name === 'locations') {
        name = 'geocoding';
      }

      if ((change === false || (change && selectName && selectName !== name))) {
        // run on load
        $select.find('option:not([data-id]):not([value=""])').each(function() {
          var value = $(this).attr('value'),
            count = 0;
  
          $.each(json, function(index, case_study) {
            var stringToCheck = JSON.stringify(case_study[name]).toLowerCase();
  
            if (name === 'topic_primary') {
              stringToCheck = stringToCheck + JSON.stringify(case_study['topic_secondary']).toLowerCase();
              stringToCheck = stringToCheck + JSON.stringify(case_study['topic_tertiary']).toLowerCase();
            }
  
            if (stringToCheck.indexOf(value.toLowerCase()) !== -1) {
              count++;
            }
          });

          if (name === 'research_categories' && count === 0) {
            $(this).remove();
          } else {
            $(this).text(value+' ('+count+')');
          }          
        });
      }
    }
  
    $select.select2({
      allowClear: true,
      placeholder: $select.attr('placeholder'),
      templateResult: function (data, container) {
        if (data.element) {
          $(container).addClass($(data.element).attr("class"));
  
          if ($(data.element).attr("data-id")) {
            $(container).attr('data-id', $(data.element).attr("data-id"));
          }
  
          if ($(data.element).attr("data-parent")) {
            $(container).attr('data-parent', $(data.element).attr("data-parent"));
          }

          $(container).attr('data-value', $(data.element).attr("data-value"));
  
          var $element = $(data.element);
          var $wrapper = $('<span></span>');
  
          $wrapper.text(data.text);
  
          $wrapper.on('click', function (evt) {
            $(this).parents('li').toggleClass('children-selected');
            getNextOptions($(this).parents('.level-1'), $select);
          });
  
          return $wrapper;
        }

        return data.text;
      }
    });
  
    $(this).on('select2:open', function (e) {
      var data = e;
  
      $(data.currentTarget).children().each(function(index, child) {
  
        /** We only care about level 1 */
        if ($(child).data('id')) {
          var id = $(child).attr('data-id');
          var children = [];
          var childActiveCount = 0;
  
          $(data.currentTarget).children().each(function(index, child) {
            if ($(child).data('parent') && $(child).attr('data-parent') == id) {
              children.push($(child));
              
              if ($(child)[0].selected) {
                childActiveCount++;
              }
            }
          });
  
          if (children.length === childActiveCount) {
            $(child).addClass('children-selected');
          } else {
            $(child).removeClass('children-selected');
          }
        }
      });
  
    });
  });
}

/** Enable tooltips */
$('.tooltip').each(function() {
  var tooltipContent = $(this).attr('data-content');
  
  tippy(this, {
    content: '<span class="info-icon">i</span><div class="tooltip-right-content">'+tooltipContent+'</div>',
    placement: 'top-end',
    allowHTML: true,
  });
});

function isObjectEmpty(object) {
  var checks = [];

  $.each(object, function(index, item) {
    if (typeof item !== 'string') {
      if (item.length > 0) {
        $.each(item, function(index, value) {
          if (value !== '') {
            checks.push(false);
          }
        });
      } else {
        checks.push(true);
      }
    } else {
      if (item !== '') {
        checks.push(false);
      } else {
        checks.push(true);
      }
    }
  });


  if (checks.indexOf(false) !== -1) {
    return false;
  }

  return true;
}

/** Handle select changes */
function generateSelectOptions() {
  selectedOptions = {};

  /** Get all selected values from dropdowns */
  $('.filter-wrap').each(function() {
    /** Select filters */
    var $select = $(this).find('select');

    if ($select.length > 0) {
      var name = $select.attr('name'),
        data = $select.select2('data');

      selectedOptions[name] = [];
      
      $.each(data, function(index, item) {
        selectedOptions[name].push(item.id);
      });
    }

    /** Radio filter */
    var $impact_radio = $(this).find('input[name="impact_area"]');
    
    if ($impact_radio.length > 0) {
      var impact_radio_value = $(this).find('input[name="impact_area"]:checked').val();
      
      if (typeof impact_radio_value !== 'undefined') {
        var name = $impact_radio.attr('name');
        selectedOptions[name] = impact_radio_value;
      }
    }
  });
}

function postFilters(selectName, change) {
  if (!isObjectEmpty(selectedOptions)) {
    $('.clear-filters-btn').show();
  } else {
    $('.clear-filters-btn').hide();
  }

  $.ajax({
    url: settings.dataUrl,
    type: "POST",
    data: selectedOptions,
    success: function(json) {
      table.clear();
      table.draw();

      /** Add data to table */
      table.rows.add(json.data.body).draw();

      /** Get the total count and render it to the page */
      updateResultsTable(json.data.total);

      /** Get option counts */
      activateFilters(json.data.body, selectName, change);
    }
  });
}

function urlencode(str) {
  var newStr = '';
  var len = str.length;

  for (var i = 0; i < len; i++) {
    var c = str.charAt(i);
    var code = str.charCodeAt(i);

    // Spaces
    if (c === ' ') {
      newStr += '+';
    }
    // Non-alphanumeric characters except "-", "_", and "."
    else if ((code < 48 && code !== 45 && code !== 46) ||
             (code < 65 && code > 57) ||
             (code > 90 && code < 97 && code !== 95) ||
             (code > 122)) {
      newStr += '%' + code.toString(16);
    }
    // Alphanumeric characters
    else {
      newStr += c;
    }
  }

  return newStr;
}

$('.filter-wrap select').on('change', function() {
  if (single) {
    generateSelectOptions();

    var nextUrl = window.location.origin+window.location.pathname;
    window.history.replaceState('', '', nextUrl);

    postFilters($(this).attr('name'), true);
  }
});

$('.filter-wrap input[name="impact_area"]').on('change', function() {
  if (single) {
    generateSelectOptions();

    var nextUrl = window.location.origin+window.location.pathname;
    window.history.replaceState('', '', nextUrl);

    postFilters($(this).attr('name'), false);
  }
});


/** Clear filters */
$('.clear-filters-btn').on('click', function() {
  /** Stop the selects running every time */
  single = false;

  /** Clear selects */
  $('.filters-wrapper .filter-select').each(function() {
    var name = $(this).attr('name');
    $(this).val('').trigger('change');
  });

  /** Clear radios */
  $('.filters-wrapper .radio-wrapper input[type="radio"]:checked').each(function() {
    $(this).prop('checked', false).trigger('change');
  });

  var nextUrl = window.location.origin+window.location.pathname,
    currentUrl = nextUrl+window.location.search;

  /** Update URL history and the clear URL */
  window.history.pushState('', '', currentUrl);
  window.history.replaceState('', '', nextUrl);

  /** Do a single post of data */
  generateSelectOptions();
  postFilters();

  /** Set it back to normal */
  single = true;
});


/** Handle search clear */
$(document).on('change input', '.search-input', function() {
  var value = $(this).val();

  if (value.length === 0) {
    window.location.href = window.location.origin;
  }
});