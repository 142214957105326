var $menu = $('.sticky-on-scroll');

if ($menu.length > 0) {
  var asideTop = $menu.offset().top - parseFloat($menu.css('marginTop').replace(/auto/, 0));

  $(window).on('scroll', function() {
    var asideY = $(this).scrollTop();
    if (asideY >= asideTop) {
      $menu.css({
        position: 'fixed',
        top: '20px',
        width: 'calc(25% - 40px)'
      });
    } else {
      $menu.removeAttr('style');
    }
  });
}