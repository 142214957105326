if ($('#uploadFile').length > 0) {
  Dropzone.options.uploadFile = {
    init: function() {
      var i = 0;

      /** Sends the file for uploading */
      this.on("sending", function(file) {
        $("#tmp-path").html('<input type="hidden" name="path" value="'+file.fullPath+'" />');
        
        /** Fly away */
        i++;
      });
      
      // Upload complete
      this.on("success", function(file, response) {
        /** Come back */
        i--;

        /** All flew away, all came back */
        if (i == 0) {
          $.ajax({
            type: 'POST',
            url: '/api/upload/complete',
            success: function(response) {
              if (response.status === 'success') {
                $('.upload-message .text').show().text(response.message);
              } else {
                $('.upload-message .text').show().addClass('error').text(response.message);
              }
            }
          });
        }
      });
      
    }
  };

  var myDropzone = new Dropzone("#uploadFile", {
    url: "/api/upload",
    clickable: false
  });
}