$('.panel .panel-body').hide();
$('.panel:first-child .panel-heading').addClass('active');
$('.panel:first-child .panel-body').slideDown();

$('.panel .panel-heading').on('click', function () {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
    $(this).siblings('.panel-body').slideUp();
  } else {
    $('.panel-heading').removeClass('active');
    $(this).addClass('active');
    $('.panel-body').slideUp();
    $(this).siblings('.panel-body').slideDown();
  }
});

if (window.location.hash && (window.location.hash.indexOf('#faq-') !== -1)) {
  var target = $(window.location.hash);

  // Does a scroll target exist?
  if (target.length) {
    $('.panel .panel-heading').removeClass('active');
    $('.panel .panel-body').hide();

    $('html, body').animate({
      scrollTop: target.offset().top - 40
    }, 400, function () {
      var $target = $(target);
      $target.find('.panel-heading').addClass('active');
      $target.find('.panel-body').slideDown();
    });
  }
}
